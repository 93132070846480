import { useEffect, useState } from 'react'
import ConfigurableButton, {
  type Props as ConfigurableButtonProps,
} from '@/app/components/ConfigurableButton'
import { User, Heart } from 'react-feather'
import { Modal, Text, ToggleButton } from '@/components'
import { Props as ModalProps } from '@/components/Modal/Modal'
import Piggy from '@/components/Icon/CustomIcons/Piggy.svg?react'
import square_air from '../../img/1080x1080_air.png'
import square_money from '../../img/1080x1080_money.png'
import portrait_air from '../../img/1080x1920_air.png'
import portrait_money from '../../img/1080x1920_money.png'
import landscape_air from '../../img/1200x630_air.png'
import landscape_money from '../../img/1200x630_money.png'
import { useAppSelector } from '@/hooks'

const shareImages = {
  portrait: {
    air: portrait_air,
    money: portrait_money,
  },
  landscape: {
    air: landscape_air,
    money: landscape_money,
  },
  square: {
    air: square_air,
    money: square_money,
  },
}
const shareMessage = {
  air: 'help save the planet',
  money: 'save your wallet',
}

const ShareButton = ({
  onClick,
  disabled = false,
  ...props
}: Omit<ConfigurableButtonProps, 'id'>) => {
  return (
    <ConfigurableButton
      id="referralInvite"
      variant="primary"
      className="mt-2"
      onClick={onClick}
      disabled={disabled}
      {...props}
    >
      {props.children ? (
        props.children
      ) : (
        <div className="flex items-center justify-center gap-2">
          <User className="h-5 text-themed-white" />
          Invite Friend
        </div>
      )}
    </ConfigurableButton>
  )
}

export default function ShareComponent(props?: {
  buttonProps?: Omit<ConfigurableButtonProps, 'id' | 'onClick' | 'children'>
  children?: React.ReactNode
}) {
  const [shareAvailable, setShareAvailable] = useState(false)
  const [customReferralModalOpen, setCustomReferralModalOpen] = useState(false)

  useEffect(() => {
    if (navigator?.canShare?.({ text: 'test' })) setShareAvailable(true)
  }, [navigator.share])

  if (!shareAvailable) return null

  const openModal = async () => {
    setCustomReferralModalOpen(true)
  }

  return (
    <>
      <ShareButton {...props?.buttonProps} onClick={openModal}>
        {props?.children}
      </ShareButton>
      <CustomReferralModal
        id="testModal"
        open={customReferralModalOpen}
        onClose={() => setCustomReferralModalOpen(false)}
        className="!max-w-md !w-full !p-4 !gap-4 !flex !flex-col !items-center !justify-center"
      />
    </>
  )
}

const CustomReferralModal = (props: ModalProps) => {
  const user = useAppSelector((state) => state.user.user)
  const purposeOptions = [
    {
      label: 'Save the Planet',
      icon: <Heart className="h-8 w-8 text-themed-pink-500" />,
      value: 'air',
    },
    {
      label: 'Save Money',
      icon: <Piggy className="h-8 w-8 text-themed-green-500" />,
      value: 'money',
    },
  ] as const
  const layoutOptions = [
    {
      label: 'Portrait',
      icon: (
        <div className="w-8 aspect-[3/4] border-dashed border rounded-md border-themed-accent"></div>
      ),
      value: 'portrait',
    },
    {
      label: 'Landscape',
      icon: (
        <div className="h-8 aspect-[4/3] border-dashed border rounded-md border-themed-accent"></div>
      ),
      value: 'landscape',
    },
    {
      label: 'Square',
      icon: (
        <div className="w-8 aspect-square border-dashed border rounded-md border-themed-accent"></div>
      ),
      value: 'square',
    },
  ] as const
  type PurposeOptions = (typeof purposeOptions)[number]['value']
  type LayoutOptions = (typeof layoutOptions)[number]['value']
  const [purpose, setPurpose] = useState<PurposeOptions | null>(null)
  const [layout, setLayout] = useState<LayoutOptions | null>(null)
  const url = `https://optiwatt.com/?referral_code=${user?.profile?.referral_code}`

  useEffect(() => {
    if (!props.open) return
    setPurpose(null)
    setLayout(null)
  }, [props.open])

  const handleShare = async () => {
    if (!purpose || !layout) return
    const shareData = {
      text: `${user?.first_name} invited you to join Optiwatt! Charge your EV the smart way ${shareMessage[purpose]} by connecting your electric so you can track and save when charging. ${url}`,
      files: [
        await fetch(shareImages[layout][purpose])
          .then((r) => r.blob())
          .then((blob) => new File([blob], 'image.png', { type: 'image/png' })),
      ],
    }
    try {
      navigator.share(shareData).then(() => {
        props?.onClose?.()
      })
    } catch (err) {
      console.error('Error sharing', err)
    }
  }

  return (
    <Modal {...props}>
      <div className="flex flex-col items-center justify-center gap-6 p-4 max-w-md">
        <div id="title-section" className="w-full flex flex-col gap-4">
          <Text variant="h2" className="w-full">
            Invite Friend
          </Text>
          <Text variant="body2" className="w-full">
            Customize your referral by choosing a purpose and layout below
          </Text>
        </div>
        <div id="purpose-section" className="w-full flex flex-col gap-4">
          <Text variant="h3" className="w-full !text-left">
            Choose a Purpose
          </Text>
          <ToggleButton.ToggleButtonGroup
            value={purpose}
            onChange={(value) => {
              setPurpose(value as typeof purpose)
            }}
          >
            {purposeOptions.map((option, i) => (
              <ToggleButton.ToggleButton
                key={i}
                id={option.label}
                label={option.label}
                value={option.value}
                icon={option.icon}
              />
            ))}
          </ToggleButton.ToggleButtonGroup>
        </div>
        <div id="layout-section" className="w-full flex flex-col gap-4">
          <Text variant="h3" className="w-full !text-left">
            Choose a Layout
          </Text>
          <ToggleButton.ToggleButtonGroup
            value={layout}
            onChange={(value) => {
              setLayout(value as typeof layout)
            }}
            className="h-24"
          >
            {layoutOptions.map((option, i) => (
              <ToggleButton.ToggleButton
                key={i}
                id={option.label}
                label={option.label}
                value={option.value}
                icon={option.icon}
              />
            ))}
          </ToggleButton.ToggleButtonGroup>
        </div>
        <div id="submit-section" className="w-full flex flex-col gap-4">
          <ShareButton
            disabled={!purpose || !layout}
            onClick={() => {
              handleShare()
            }}
          />
        </div>
      </div>
    </Modal>
  )
}
